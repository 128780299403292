import React from "react"
import Grid from "@material-ui/core/Grid"
import { Link } from 'gatsby';
import { graphql } from "gatsby"
import serviceStyles from "./service.module.scss"
import SEO from "../../components/seo"
import diseño from "../../images/diseño.jpg"

export default function Design({ data }) {
  return (
    <>
      <SEO
        title="Design"
        description="Al diseñar, la persona no sólo tiene en cuenta aspectos estéticos, 
                            sino también cuestiones funcionales y técnicas."
        image={`https://www.ppdc.mx${diseño}`}
        url="/services/design"
      />
      <Grid container className={serviceStyles.serviceHero}>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          className={serviceStyles.serviceHeroTitleContainer}
        >
          <h1 className={serviceStyles.serviceHeroTitle}>Diseño</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          className={serviceStyles.serviceHeroImageDescriptionContainerDesign}
        >
          <div className={serviceStyles.serviceHeroDescriptionContainer}>
            <h1 className={serviceStyles.serviceHeroTitleTwo}>Diseño</h1>
            <p className={serviceStyles.serviceHeroDescription}>
              Al diseñar, la persona no sólo tiene en cuenta aspectos estéticos,
              sino también cuestiones funcionales y técnicas. Esto exige a los
              diseñadores estudios, investigaciones y tareas de modelado que le
              permitan encontrar la mejor manera de desarrollar el objeto que
              pretenden crear.
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container className={serviceStyles.gridContainer}>
        {data.allAirtable.edges.map(edge => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={serviceStyles.serviceContainer}
              id={edge.node.id}
            >
              <img
                src={
                  edge.node.data.Image
                    ? edge.node.data.Image
                    : edge.node.data.ImageFile.map(image => {
                        return image.url
                      })
                }
                alt={`Imagen de ${edge.node.data.Title}`}
                className={serviceStyles.serviceImage}
              />
              <div className={serviceStyles.serviceTextArea}>
                <h2 className={serviceStyles.serviceTitle}>
                  {edge.node.data.Title}
                </h2>
                {/* <p className={serviceStyles.serviceDescription}>{edge.node.data.Description}</p> */}
                <Link title="contact" paintDrip hex="#DAD4CC" to="/contact">
                  <button className={serviceStyles.ctaButton}>Saber Más</button>
                </Link>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export const data = graphql`
  query airtableDesign {
    allAirtable(
      sort: { fields: data___Order }
      filter: { data: { Category: { eq: "Design" } } }
    ) {
      edges {
        node {
          id
          data {
            Description
            Category
            Title
            Image
            ImageFile {
              url
            }
          }
        }
      }
    }
  }
`
